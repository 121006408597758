import React,{useState,useEffect} from 'react';
import TestimonialItem from "../../../Items/TestimonialItem";
import Profile1 from "../../../../assets/image/profileimage.webp"
import Profile2 from "../../../../assets/image/profileimage2.webp"
import Profile3 from "../../../../assets/image/profileimage3.webp"
import Profile4 from "../../../../assets/image/profileimage4.webp"
import noImage from "../../../../assets/image/no-data-found.jpg"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "animate.css";

import { postData } from '../../../../services/api';
import { useTranslation } from "react-i18next";

const ClientTestimonials = () => {
  const { i18n,t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [testimonials,setTestimonials] = useState([]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const result = await postData("/testimonial/list", {
        sortQuery: {created_at:1},
        status : 1,
      });
      if (result.data) {
        setTestimonials(
          result.data.map((item,index)=>{
            return {
              name : item?.name?.[i18n?.language],
              designation : item?.company?.[i18n?.language],
              image : noImage,
              description : item?.comments?.[i18n?.language],
            }
          })
        );
        setLoading(false);
      }
    };
    loadList();
  }, []);

  const TestimonialData = {
    title:t('What_do_our_clients_say'),
    Testimonial : testimonials
    // Testimonial:[
    //   {
    //     name:"Doug Petersen",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile1,
    //     description:"<p>“ SuccessionNow has given GECU the tools needed to identify talent gaps and create career paths for an effective, seamless succession planning process.”</p>",
    //   },
    //   {
    //     name:"Doug Petersen",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile2,
    //     description:"<p>“SuccessionNow helps us to address staffing surprises proactively and more effectively.”</p>",
    //   },
    //   {
    //     name:"Debbie H. Jones",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile3,
    //     description:"<p>“Succession Planning is something that is very important to our Board. SuccessionNow has provided us a very easy approach to identifying, evaluating & developing a workable succession plan; it has been terrific and is used by our entire senior management staff.”</p>",
    //   },
    //   {
    //     name:"Doug Petersen",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile4,
    //     description:"<p>“ SuccessionNow has given GECU the tools needed to identify talent gaps and create career paths for an effective, seamless succession planning process.”</p>",
    //   },
    //   {
    //     name:"Doug Petersen",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile2,
    //     description:"<p>“SuccessionNow helps us to address staffing surprises proactively and more effectively.”</p>",
    //   },
    //   {
    //     name:"Debbie H. Jones",
    //     designation:"President/CEO @GECU Federal Credit Union",
    //     image:Profile3,
    //     description:"<p>“Succession Planning is something that is very important to our Board. SuccessionNow has provided us a very easy approach to identifying, evaluating & developing a workable succession plan; it has been terrific and is used by our entire senior management staff.”</p>",
    //   },
    // ]
  }
  return (
    <section className="relative w-full py-10 bg-white" id="testimonial-section">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
          <div className="max-w-3xl w-full mx-auto text-center mb-5 xs:mb-10 xl:mb-20">
                <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:TestimonialData.title}}></h2>
          </div>
        
          <div className="justify-center block lg:flex items-center relative gap-5">
              {/* <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  // loop={true}
                  navigation={false}
                  modules={[Navigation, Autoplay, EffectFade]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },
                    
                    1100: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  className="w-full"
                > */}
                  {TestimonialData.Testimonial.map((item, index) => (
                    <div className="w-full lg:w-1/3 mb-5" key={item?.id || index}>
                      <TestimonialItem data={item} />
                    </div>
                  ))}
                  
              {/* </Swiper> */}
          </div>
        
      </div>
    </section>
  );
};

export default ClientTestimonials;